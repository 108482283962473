import React from "react";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";

export default function WellboatAutomaticStatus({ wellboat }) {
  const statusStyle = {
    true: { color: "#FF3D00" },
    false: { color: "#B8B8B84D" },
  };
  return <MotionPhotosAutoIcon sx={statusStyle[wellboat.alarmsAutomated]} />;
}
