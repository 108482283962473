import React from "react";
import { Box } from "@mui/material";

export default function DevMark() {
  // This is only used to show a "DEV" mark on the top left corner of the screen
  // when the app is running in development, to make it easier to distinguish
  // preventing accidental changes in production.

  return (
    <>
      {process.env.NODE_ENV === "development" && (
        <Box
          component="div"
          sx={{
            position: "fixed",
            background: "#08769b",
            boxShadow: "0 0 0 999px #08769b",
            clipPath: "inset(0 -100%)",
            inset: "0 auto auto 0",
            transformOrigin: "100% 0",
            transform: "translate(-29.3%) rotate(-45deg)",
            color: "white",
            zIndex: 9999,
          }}
        >
          DEV
        </Box>
      )}
    </>
  );
}
