import * as React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/es";
dayjs.locale("es");
import timezone from "dayjs/plugin/timezone.js";
dayjs.extend(timezone);
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// use dayjs globally
window.dayjs = dayjs;

// utility constante for timezone
window.TIMEZONE = "America/Santiago";

// utility constants for time formats
window.SHORT_TIME_FORMAT = "HH:mm";
window.SHORT_DATE_FORMAT = "DD/MM/YYYY";
window.SEMI_LONG_DATE_FORMAT = "ddd DD/MM/YYYY";
window.SHORT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
window.LONG_DATE_TIME_FORMAT = "dddd DD/MM/YYYY HH:mm";
window.SEMI_LONG_DATE_TIME_FORMAT = "ddd DD/MM/YYYY HH:mm";
window.SEMI_LONG_DATE_TIME_WITHOUT_YEAR_FORMAT = "ddd DD/MM HH:mm";

export default function LocalizationWrapper({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      {children}
    </LocalizationProvider>
  );
}
