import React from "react";
import Drawer from "@mui/material/Drawer";
import { Grid, Button, Typography, Box, Checkbox } from "@mui/material";
import UserFieldFormComponent from "./UserFieldFormComponent";
import RolesFieldFormComponent from "./RolesFieldFormComponent";

export default function FormDrawerComponent({
  openForm,
  setOpenForm,
  currentUser,
  setUsers,
}) {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    roles: [],
  });
  const [messageError, setMessageError] = React.useState();
  const [roles, setRoles] = React.useState([]);
  const [rolesSelected, setRolesSelected] = React.useState([]);

  React.useEffect(() => {
    if (currentUser) {
      setFormData({
        name: currentUser.name || "",
        email: currentUser.email || "",
        phone: currentUser.phone || "",
        roles: currentUser.roles || [],
      });
      loadRoles();
    }
  }, [currentUser]);

  const loadRoles = () => {
    axios
      .get("/roles")
      .then((response) => {
        setRoles(response.data);
        setRolesSelected(currentUser?.rolesAssigned?.map((r) => r.role) || []);
      })
      .catch((response) => {
        setMessageError(response.response.data.message);
      });
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!open) setMessageError(null);
    setOpenForm(open);
  };

  const loadActionUrlAndParams = () => {
    let action, url;
    const params = { ...formData };
    if (currentUser.id) {
      action = "put";
      url = `/users/${currentUser.id}`;
    } else {
      action = "post";
      url = "/users";
    }

    return { action, url, params };
  };

  const handleSaveForm = () => {
    const { action, url, params } = loadActionUrlAndParams();
    axios[action](url, params)
      .then((response) => {
        setUsers(response.data);
        setOpenForm(false);
      })
      .catch((response) => {
        setMessageError(response.response.data.message);
      });
  };

  const handleUpdateAttribute = (attribute, value) => {
    setFormData({ ...formData, [attribute]: value });
  };

  const loadTitleForm = () => {
    return currentUser.id == null ? "Crear usuario" : "Editar usuario";
  };

  return (
    <Drawer anchor="right" open={openForm} onClose={toggleDrawer(false)}>
      <Grid container sx={{ mt: 4, p: 2 }}>
        <Grid item>
          <Typography variant="h6">{loadTitleForm()}</Typography>
        </Grid>
        {messageError && (
          <Box sx={{ width: "100%" }}>
            <Typography>{messageError}</Typography>
          </Box>
        )}
        <UserFieldFormComponent
          fieldName={"name"}
          placeholder={"Nombre (requerido)"}
          value={formData.name}
          helperText={"El nombre es un campo requerido"}
          onChangeCallback={handleUpdateAttribute}
        />
        <UserFieldFormComponent
          fieldName={"email"}
          value={formData.email}
          helperText={"Ingresa un correo válido"}
          placeholder={"Email (requerido)"}
          inputProps={{
            type: "email",
          }}
          onChangeCallback={handleUpdateAttribute}
        />
        <UserFieldFormComponent
          fieldName={"phone"}
          value={formData.phone}
          helperText={"El teléfono es un campo requerido"}
          placeholder={"Número de teléfono (11 digitos, requerido)"}
          inputProps={{
            type: "number",
          }}
          onChangeCallback={handleUpdateAttribute}
        />
        <RolesFieldFormComponent
          roles={roles}
          rolesSelected={rolesSelected}
          setRolesSelected={setRolesSelected}
          onChangeCallback={handleUpdateAttribute}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
          px: "20px",
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={toggleDrawer(false)}
        >
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={handleSaveForm}>
          Guardar
        </Button>
      </Box>
    </Drawer>
  );
}
