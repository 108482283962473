import React from "react";

export default function useOnWindowResize(handler) {
  React.useEffect(() => {
    window.addEventListener("resize", handler);
    handler();

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);
}
