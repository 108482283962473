import React from "react";
import { Container, Stack } from "@mui/system";
import { Paper, Typography } from "@mui/material";
import { useWellboats } from "../../contexts/WellboatsContext";
import SelectSensorsComponent from "./modal_export/SelectSensorsComponent";
import { DateTimePicker } from "@mui/x-date-pickers";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

export default function ModalExport({ openModal, setOpenModal }) {
  const { currentWellboat } = useWellboats();
  const [startTime, setStartTime] = React.useState(dayjs().add(-3, "hours"));
  const [endTime, setEndTime] = React.useState(dayjs());
  const [warehouses, setWarehouses] = React.useState([]);

  React.useEffect(() => {
    if (currentWellboat)
      axios.get(`/catalog/exports/${currentWellboat.id}`).then((response) => {
        setWarehouses(response.data);
      });
  }, [currentWellboat]);

  const handleOnClose = () => {
    setOpenModal(false);
  };

  const handleExportSensors = (e) => {
    const checkedSensorsIds = [];
    warehouses.forEach((warehouse) => {
      warehouse.sensors.forEach((sensor) => {
        if (sensor.checked) checkedSensorsIds.push(sensor.id);
      });
    });

    axios.post("/catalog/exports", {
      wellboatId: currentWellboat.id,
      exportedSensors: checkedSensorsIds,
      startTime: startTime,
      endTime: endTime,
    });
  };

  return (
    <Dialog open={openModal} onClose={handleOnClose} maxWidth="lg">
      <Paper sx={{ p: 2 }}>
        <Container>
          <Stack spacing={2} textAlign="center" justifyItems="center">
            <Typography variant="h5" sx={{ pb: 2 }}>
              Exportar datos de {currentWellboat?.name}
            </Typography>
            <Container>
              <Stack direction="row" spacing={3}>
                <DateTimePicker
                  value={startTime || ""}
                  onChange={(dateTime) => setStartTime(dateTime)}
                  sx={{ display: "flex", width: "13rem" }}
                  disableFuture={true}
                  label="Desde"
                />
                <DateTimePicker
                  value={endTime || ""}
                  onChange={(dateTime) => setEndTime(dateTime)}
                  sx={{ display: "flex", width: "13rem" }}
                  disableFuture={true}
                  label="Hasta"
                  minDateTime={startTime}
                />
              </Stack>
            </Container>
            <Container>
              <SelectSensorsComponent
                warehouses={warehouses}
                setWarehouses={setWarehouses}
              />
            </Container>
            <Container>
              <Button
                variant="contained"
                onClick={handleExportSensors}
                startIcon={<DownloadIcon />}
                sx={{
                  maxWidth: "15rem",
                }}
              >
                DESCARGAR
              </Button>
            </Container>
          </Stack>
        </Container>
      </Paper>
    </Dialog>
  );
}
