import * as React from "react";
import { styled, TableRow } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
  backgroundColor: index % 2 === 0 ? theme.palette.action.hover : "transparent",
}));

export default function StrippedTableRow({ children, index }) {
  return <StyledTableRow index={index}>{children}</StyledTableRow>;
}
