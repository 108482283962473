import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";

export default function DelayedProgress({ delay = 500, children, ...props }) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return <>{show && (children ? children : <LinearProgress {...props} />)}</>;
}
