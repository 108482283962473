import React, { createContext, useState } from "react";

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const [wellboats, setWellboats] = useState([]);
  const [selectedWellboat, setSelectedWellboat] = useState(null);
  const [currentChart, setCurrentChart] = React.useState(null);
  const [listCharts, setListCharts] = React.useState([]);
  const [currentWarehouse, setCurrentWarehouse] = React.useState();

  React.useEffect(() => {
    axios
      .get("/wellboats")
      .then((response) => setWellboats(response.data))
      .catch((error) => {
        console.error(error);
        alert(
          "Ocurrió un error en el sistema. Actualiza la página e intenta nuevamente.",
        );
      });
  }, []);

  const warehouses = React.useMemo(
    () => selectedWellboat?.warehouses,
    [selectedWellboat],
  );

  React.useEffect(() => setCurrentWarehouse(warehouses?.[0]), [warehouses]);

  return (
    <AdminContext.Provider
      value={{
        wellboats,
        selectedWellboat,
        setSelectedWellboat,
        currentChart,
        setCurrentChart,
        listCharts,
        setListCharts,
        warehouses,
        currentWarehouse,
        setCurrentWarehouse,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

const useAdmin = () => React.useContext(AdminContext);

export { AdminProvider, useAdmin };
