import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import FormDrawerComponent from "./users/FormDrawerComponent";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "@mui/icons-material/Search";
import removeDiacritics from "../../../utils/removeDiacritics";
import useConfirm from "../../../hooks/useConfirm";

export default function UsersBody() {
  const [users, setUsers] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});
  const [openForm, setOpenForm] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [getConfirmation, Confirmation] = useConfirm();

  React.useEffect(() => {
    axios.get("/users").then((response) => {
      setUsers(response.data);
    });
  }, []);

  const handleAddUser = () => {
    setCurrentUser({});
    setOpenForm(true);
  };

  const handleEditUser = (user) => {
    setCurrentUser(user);
    setOpenForm(true);
  };

  const handleDestroyUser = (user) => {
    getConfirmation({
      title: `¿Eliminar usuario ${user.name}?`,
      content: `Esta acción no se puede deshacer.`,
    }).then((response) => {
      if (response) {
        axios.delete("/users/" + user.id).then((response) => {
          setUsers(response.data);
        });
      }
    });
  };

  const filteredUsers = React.useMemo(() => {
    if (searchTerm == "" || searchTerm == null) return users;
    const normalizedSearchTerm = removeDiacritics(searchTerm.toLowerCase());
    return users.filter(
      (user) =>
        removeDiacritics(user.email.toLowerCase()).includes(
          normalizedSearchTerm,
        ) ||
        removeDiacritics(user.name.toLowerCase()).includes(
          normalizedSearchTerm,
        ),
    );
  }, [users, searchTerm]);

  const memoizingUsers = React.useMemo(() => {
    return filteredUsers.map((user, index) => {
      return (
        <TableRow
          hover
          key={user.id}
          sx={{ verticalAlign: "middle", cursor: "pointer" }}
          onClick={() => {
            handleEditUser(user);
          }}
        >
          <TableCell>{user.name}</TableCell>
          <TableCell>{user.email}</TableCell>
          <TableCell>{user.phone}</TableCell>
          <TableCell sx={{ whiteSpace: "pre-line" }}>
            {user.rolesAssigned.map((r) => r.label).join("\n")}
          </TableCell>
          <TableCell>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDestroyUser(user);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }, [filteredUsers]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
        }}
      >
        <FormControl sx={{ width: "500px" }} variant="standard">
          <InputLabel htmlFor="input-with-icon-adornment">Buscar</InputLabel>
          <Input
            id="input-with-icon-adornment"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </FormControl>
        <Button
          color="primary"
          onClick={handleAddUser}
          startIcon={<AddIcon />}
          variant="contained"
        >
          Agregar usuario
        </Button>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{memoizingUsers}</TableBody>
      </Table>
      <FormDrawerComponent
        openForm={openForm}
        setOpenForm={setOpenForm}
        currentUser={currentUser}
        setUsers={setUsers}
      />
      <Confirmation />
    </>
  );
}
