import React from "react";
import { TextField, FormControl } from "@mui/material";

export default function FieldFormComponent({
  fieldName,
  placeholder,
  inputProps,
  onChangeCallback,
  value,
  helperText,
  disabled,
}) {
  const [errorState, setErrorState] = React.useState(false);

  const handleOnChange = (e) => {
    onChangeCallback(fieldName, e.target.value);
    setErrorState(!e.target.validity.valid);
  };

  return (
    <FormControl sx={{ width: "100%", mb: 2 }}>
      <TextField
        placeholder={placeholder}
        inputProps={inputProps || {}}
        disabled={disabled}
        required
        fullWidth
        variant="outlined"
        onChange={handleOnChange}
        error={errorState}
        value={value}
        size="small"
        helperText={errorState ? helperText || "" : ""}
      />
    </FormControl>
  );
}
