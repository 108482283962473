import React from "react";
import DisplayChartsSetting from "./DisplayChartsSetting";
import AlarmSetting from "./AlarmSetting";
import { Box } from "@mui/material";

export default function AlarmLiveSetting() {
  return (
    <Box sx={{ display: "flex", my: 1, px: 2 }}>
      <DisplayChartsSetting />
      <AlarmSetting />
    </Box>
  );
}
