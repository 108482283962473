import React from "react";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { IconButton } from "@mui/material";
import { Box } from "@mui/material";

const styleForFavorite = {
  true: { color: "#FDCE29" },
  false: { color: "transparent" },
};

export default function FavoriteButton({ chart }) {
  const [isFavorite, setIsFavorite] = React.useState();

  React.useEffect(() => {
    setIsFavorite(chart.isFavorite);
  }, [chart]);

  const updateFavorite = () => {
    const thenCallback = () => {
      setIsFavorite(!isFavorite);
    };
    if (isFavorite) {
      axios.delete(`/charts/${chart.id}/favorites`).then(thenCallback);
    } else {
      axios.post(`/charts/${chart.id}/favorites`).then(thenCallback);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <IconButton
        onClick={() => updateFavorite()}
        title={
          isFavorite
            ? "Quitar este gráfico del dashboard"
            : "Agregar este gráfico al dashboard"
        }
      >
        {isFavorite ? (
          <StarOutlinedIcon sx={styleForFavorite[isFavorite]} />
        ) : (
          <StarOutlineIcon />
        )}
      </IconButton>
    </Box>
  );
}
