import * as React from "react";
import Dashboard from "@mui/icons-material/Dashboard";
import AutoGraph from "@mui/icons-material/AutoGraph";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import { AppBar, Toolbar, Box, Stack } from "@mui/material";
import logo from "../../../assets/images/logo.png";
import NavItem from "./NavItem";
import DevMark from "../../utils/DevMark";
import { useLocation } from "react-router-dom";
import AlertIconNotification from "./AlertIconNotification";
import UserMenu from "./UserMenu";
import MobileMenu from "./MobileMenu";

export const NAVBAR_ID = "app-navbar";

const navItems = () => [
  {
    name: "Dashboard",
    path: "/dashboard",
    Icon: Dashboard,
    key: "dashboard",
  },
  {
    name: "Catálogo",
    path: "/catalog",
    Icon: AutoGraph,
    key: "catalog",
  },
  {
    name: "Admin",
    path: "/admin",
    Icon: AdminPanelSettings,
    key: "admin",
  },
];

export default function Navbar({ userName }) {
  const location = useLocation();
  const [, setMobileMenuOpen] = React.useState(false);

  const items = React.useMemo(() => {
    return navItems().map((item, index) => (
      <NavItem
        key={index}
        name={item.name}
        path={item.path}
        icon={<item.Icon />}
        active={location.pathname === item.path}
      />
    ));
  }, [location]);

  return (
    <AppBar
      id={NAVBAR_ID}
      position="relative"
      color="background"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, marginBottom: 1 }}
    >
      <DevMark />
      <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            "& img": {
              verticalAlign: "sub",
            },
            display: "flex",
            flexGrow: { xs: 1, md: 0 },
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MobileMenu navItems={navItems()} />
          <img
            src={logo}
            style={{
              maxHeight: "2rem",
              width: "auto",
            }}
            alt="logotipo"
          />
        </Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            ml: 5,
          }}
        >
          {items}
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AlertIconNotification />
          <UserMenu userName={userName} />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
