import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Box, Typography, IconButton, Divider, Stack } from "@mui/material";

export default function UserMenu({ userName }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutClick = () => {
    axios.delete("/users/sign_out").then(() => {
      window.location.href = "/";
    });
    handleClose();
  };

  return (
    <Box>
      <Button
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        startIcon={<AccountCircle />}
        sx={{
          fontWeight: 400,
          fontSize: "0.9rem",
          borderRadius: 0,
          py: 1.5,
          display: { xs: "none", md: "flex" },
        }}
      >
        {userName}
      </Button>
      <IconButton
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "user-menu-button",
        }}
      >
        <MenuItem disableRipple sx={{ display: { xs: "flex", md: "none" } }}>
          <Stack direction="row" spacing={2}>
            <AccountCircle />
            <Typography variant="body1" textAlign="center">
              {userName}
            </Typography>
          </Stack>
        </MenuItem>
        <Divider sx={{ display: { xs: "flex", md: "none" } }} />
        <MenuItem onClick={logoutClick}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </Box>
  );
}
