import React from "react";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";

export default function SortingButtons({ chart }) {
  const { fetchCharts, currentWellboat } = useWellboatCharts();

  const handleUp = React.useCallback(
    (chart) => {
      axios
        .put(`/users/charts/${chart.userChart.id}/positions`, {
          direction: "up",
        })
        .then(() => {
          fetchCharts({ newCurrentWellboat: currentWellboat });
        });
    },
    [fetchCharts],
  );

  const handleDown = React.useCallback(
    (chart) => {
      axios
        .put(`/users/charts/${chart.userChart.id}/positions`, {
          direction: "down",
        })
        .then(() => {
          fetchCharts({ newCurrentWellboat: currentWellboat });
        });
    },
    [fetchCharts],
  );

  return (
    <Stack direction="column" justifyContent="center" flexGrow={1}>
      <IconButton onClick={() => handleUp(chart)}>
        <KeyboardArrowUpIcon />
      </IconButton>
      <IconButton onClick={() => handleDown(chart)}>
        <KeyboardArrowDownIcon />
      </IconButton>
    </Stack>
  );
}
