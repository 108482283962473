import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useWellboats } from "./WellboatsContext";

const WellboatChartsContext = createContext();

const bothDatesAreFilled = (currentWellboat) =>
  currentWellboat.startTime && currentWellboat.endTime;

const datesAreValid = (currentWellboat) => {
  if (bothDatesAreFilled(currentWellboat)) {
    return currentWellboat.startTime < currentWellboat.endTime;
  }
  return false;
};

export function WellboatChartsProvider({ children }) {
  const {
    wellboats,
    currentWellboat,
    setCurrentWellboat,
    loadingWellboats,
    setLoadingCharts: wellboatsContextSetLoadingCharts,
  } = useWellboats();
  const [section, setSection] = useState();
  const [charts, setCharts] = useState([]);
  const cancelController = React.useRef(new AbortController());
  const [loadingCharts, setLoadingCharts] = useState(false);
  const [error, setError] = useState();
  const lastWellboatId = React.useRef();
  const lastSection = React.useRef();

  const setLoadingChartsOnAllContexts = (value) => {
    setLoadingCharts(value);
    wellboatsContextSetLoadingCharts(value);
  };

  const fetchCharts = useCallback(
    ({ newCurrentWellboat }) => {
      if (section !== undefined) {
        cancelController.current?.abort();
        cancelController.current = new AbortController();
        setLoadingChartsOnAllContexts(true);
        let startTime = null;
        let endTime = null;
        if (!newCurrentWellboat.live) {
          if (datesAreValid(newCurrentWellboat)) {
            startTime = newCurrentWellboat.startTime.toISOString();
            endTime = newCurrentWellboat.endTime.toISOString();
          } else {
            setCharts([]);
            setLoadingChartsOnAllContexts(false);
            return;
          }
        }
        axios
          .get(`/wellboats/${newCurrentWellboat.id}/${section}`, {
            params: {
              startTime,
              endTime,
            },
            signal: cancelController.current.signal,
          })
          .then((response) => {
            // ensure it shows the correct wellboat tab selected
            // based on the real data returned from the server
            // to avoid any inconsistency on data visualization
            const selectedCurrentWellboat = wellboats.find(
              (w) => w.id === response.data.wellboat.id,
            );
            setCurrentWellboat(selectedCurrentWellboat);
            setCharts(response.data);
            setError(null);
            lastWellboatId.current = selectedCurrentWellboat.id;
            lastSection.current = section;
          })
          .catch((error) => {
            if (error.code != "ERR_CANCELED") {
              console.log(error);
              setError(
                "Ocurrío un error al cargar los datos. Actualiza la página e intenta nuevamente.",
              );
              setCharts([]);
            }
          })
          .finally(() => {
            setLoadingChartsOnAllContexts(false);
          });
      }
    },
    [wellboats, section],
  );

  useEffect(() => {
    if (currentWellboat && section) {
      const wellboatChanged = currentWellboat.id !== lastWellboatId.current;
      const sectionChanged = section !== lastSection.current;
      const props = { newCurrentWellboat: currentWellboat };

      if (wellboatChanged || sectionChanged) {
        setCharts([]);
        fetchCharts(props);
      } else {
        fetchCharts(props);
      }
    }
  }, [currentWellboat, section]);

  useEffect(() => {
    if (loadingWellboats) {
      cancelController.current?.abort();
    }
  }, [loadingWellboats]);

  return (
    <WellboatChartsContext.Provider
      value={{
        charts,
        currentWellboat,
        section,
        setSection,
        fetchCharts,
        loadingCharts,
        error,
      }}
    >
      {children}
    </WellboatChartsContext.Provider>
  );
}

export function useWellboatCharts() {
  return useContext(WellboatChartsContext);
}
