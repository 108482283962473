import React from "react";

const NotificationsContext = React.createContext();

function NotificationsProvider({ children }) {
  const [notificationsList, setNotificationsList] = React.useState([]);

  React.useEffect(() => {
    axios.get("/users/notifications/alarms_pendings").then((response) => {
      setNotificationsList(response.data);
    });
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        notificationsList,
        setNotificationsList,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

const useNotificationsContext = () => React.useContext(NotificationsContext);

export { NotificationsProvider, useNotificationsContext };
