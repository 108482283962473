import React from "react";
import { IconButton, Menu, MenuItem, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function MobileMenu({ navItems }) {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const isOpen = Boolean(anchorEl);

  const handleMenuToggle = (event) => {
    setAnchorEl(isOpen ? null : event.target);
  };

  return (
    <IconButton
      onClick={handleMenuToggle}
      sx={{ display: { xs: "flex", md: "none" } }}
    >
      <MenuIcon />
      <Menu anchorEl={anchorEl} open={isOpen} onClose={() => setAnchorEl(null)}>
        {navItems.map((item, index) => (
          <MenuItem key={index} value={item.name}>
            <Box paddingRight={1}>
              <item.Icon />
            </Box>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </IconButton>
  );
}
