import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useWellboats } from "../../contexts/WellboatsContext";

const warehouseTitle = (warehouse) =>
  (warehouse?.name || "") + (warehouse?.enabled ? "" : " | Deshabilitada");

export default function WarehouseSideLabels() {
  const { currentWellboat } = useWellboats();

  const warehouses = React.useMemo(
    () => currentWellboat?.warehouses?.slice(0, 2),
    [currentWellboat],
  );

  return (
    <>
      <Box
        component="div"
        sx={{
          position: "sticky",
          left: 0,
          height: 0,
          width: 0,
          overflow: "visible",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            transformOrigin: "left center",
            transform: "rotate(-90deg)",
            marginTop: "45vh",
            whiteSpace: "nowrap",
            marginLeft: 2,
            color: grey[500],
            width: "20rem",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          {warehouseTitle(warehouses?.[0])}
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          position: "sticky",
          left: "100%",
          height: 0,
          width: 0,
          overflow: "visible",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            transformOrigin: "left center",
            transform: "rotate(90deg)",
            marginTop: "15vh",
            whiteSpace: "nowrap",
            marginLeft: -2.5,
            color: grey[500],
            width: "20rem",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          {warehouseTitle(warehouses?.[1])}
        </Typography>
      </Box>
    </>
  );
}
