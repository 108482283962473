import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";

export default function NavItem({ name, path, icon, active }) {
  return (
    <Button
      color={active ? "primary" : "inherit"}
      component={RouterLink}
      to={path}
      startIcon={icon}
      sx={{
        fontWeight: 400,
        fontSize: "0.9rem",
        mx: 0.5,
        borderRadius: 0,
        py: 1.5,
        borderBottom: "none",
      }}
    >
      {name}
    </Button>
  );
}
