import React from "react";
import {
  Card,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Stack } from "@mui/system";
import SensorForm from "./SensorForm";
import SensorsList from "./SensorsList";
import { useAdmin } from "../../../../contexts/AdminContext";

export default function SensorsBody() {
  const { wellboats, setSelectedWellboat, selectedWellboat, warehouses } =
    useAdmin();
  const [sensors, setSensors] = React.useState([]);
  const [sensorSelected, setSensorSelected] = React.useState(null);
  const [variables, setVariables] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  const loadSensors = async () => {
    let newSensors = [];
    await axios
      .get(`/wellboats/${selectedWellboat.id}/sensors`)
      .then((response) => {
        newSensors = response.data;
        setSensors(newSensors);
      });
    return newSensors;
  };

  React.useEffect(() => {
    setSensors(null);
    setSensorSelected(null);
    if (selectedWellboat) {
      loadSensors();
    }
  }, [selectedWellboat]);

  React.useEffect(() => {
    axios.get("/variables").then((response) => {
      setVariables(response.data);
    });
  }, []);

  const handleUpdateSensor = (_e) => {
    axios
      .put(`/sensors/${sensorSelected.id}`, sensorSelected)
      .then((response) => {
        setMessageSnackbar("Sensor actualizado con éxito");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        loadSensors().then((newSensors) => {
          setSensorSelected(newSensors.find((s) => s.id == sensorSelected.id));
        });
      })
      .catch((response) => {
        setMessageSnackbar(response.request.response);
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  const sensorsList = React.useMemo(() => {
    return (
      <List
        component="nav"
        sx={{ overflow: "auto", maxHeight: "95%", width: "25rem" }}
      >
        <>
          {sensors && sensors.length > 0 && (
            <>
              {warehouses
                .concat({ id: null, name: "Sin bodega" })
                .map((warehouse, index) => (
                  <SensorsList
                    key={index}
                    warehouse={warehouse}
                    sensors={sensors}
                    setSensors={setSensors}
                    sensorSelected={sensorSelected}
                    setSensorSelected={setSensorSelected}
                    index={index}
                  />
                ))}
            </>
          )}
          {sensors && sensors.length == 0 && (
            <Typography variant="body1" sx={{ ml: 2, mt: 2 }}>
              No hay sensores
            </Typography>
          )}
        </>
      </List>
    );
  }, [sensors, sensorSelected, warehouses]);

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ height: "80vh" }}>
        <Card sx={{ p: 2 }}>
          <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
            Wellboats
          </Typography>
          <List
            sx={{
              overflow: "auto",
              minWidth: "12rem",
              maxHeight: "95%",
            }}
          >
            {wellboats.map((wellboat) => (
              <ListItemButton
                key={wellboat.id}
                onClick={() => setSelectedWellboat(wellboat)}
                selected={selectedWellboat == wellboat}
              >
                <ListItemText primary={wellboat.name} />
              </ListItemButton>
            ))}
          </List>
        </Card>
        {selectedWellboat && (
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
              Sensores
            </Typography>
            {sensorsList}
          </Card>
        )}
        {sensorSelected && (
          <SensorForm
            sensor={sensorSelected}
            setSensor={setSensorSelected}
            variables={variables}
            warehouses={warehouses}
            handleUpdateSensor={handleUpdateSensor}
          />
        )}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </>
  );
}
