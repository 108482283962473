import React from "react";
import DashboardHeader from "./DashboardHeader";
import DashboardBody from "./DashboardBody";
import { NAVBAR_ID } from "../navbar/Navbar";
import useElementTotalHeight from "../../hooks/useElementTotalHeight";
import { Box } from "@mui/material";

export default function DashboardView() {
  const navBarTotalHeight = useElementTotalHeight(NAVBAR_ID);

  return (
    <Box
      key="dashboard-view"
      component="div"
      sx={{
        height: `calc(100vh - ${navBarTotalHeight}px)`,
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DashboardHeader />
      <DashboardBody />
    </Box>
  );
}
