import React from "react";

const WellboatsContext = React.createContext();

function WellboatsProvider({ children }) {
  const [wellboats, setWellboats] = React.useState([]);
  const [currentWellboat, setCurrentWellboat] = React.useState(null);
  const wellboatsById = React.useRef({});
  const cancelController = React.useRef(new AbortController());
  const [loadingWellboats, setLoadingWellboats] = React.useState(false);
  const [loadingCharts, setLoadingCharts] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    wellboats.map((wellboat) => {
      wellboatsById.current[wellboat.id] = wellboat;
    });
  }, [wellboats]);

  const fetchWellboats = React.useCallback(
    ({ newCurrentWellboatId } = {}) => {
      cancelController.current?.abort();
      cancelController.current = new AbortController();
      setLoadingWellboats(true);
      axios
        .get("/wellboats", { signal: cancelController.current.signal })
        .then((response) => {
          const wellboatsObjects = response.data.map((wellboat) => {
            const liveStatus = wellboatsById.current[wellboat.id]?.live;

            return {
              ...wellboat,
              startTime: wellboatsById.current[wellboat.id]?.startTime,
              endTime: wellboatsById.current[wellboat.id]?.endTime,
              live: liveStatus != null ? liveStatus : true,
            };
          });
          setWellboats(wellboatsObjects);

          newCurrentWellboatId ||= currentWellboat?.id;
          const currentWellboatToSet =
            wellboatsObjects.find((w) => w.id === newCurrentWellboatId) ||
            wellboatsObjects[0];

          setCurrentWellboat(currentWellboatToSet);
          setError(null);
        })
        .catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            console.log(error);
            setError(
              "Ocurrió un error en el sistema. Actualiza la página e intenta nuevamente.",
            );
          }
        })
        .finally(() => {
          setLoadingWellboats(false);
        });
    },
    [currentWellboat],
  );

  React.useEffect(() => {
    fetchWellboats();
  }, []);

  React.useEffect(() => {
    if (!loadingWellboats && !loadingCharts) {
      const interval = setInterval(fetchWellboats, 1000 * 15);

      return () => clearInterval(interval);
    }
  }, [fetchWellboats, loadingWellboats, loadingCharts]);

  const rebuildWellboats = React.useCallback(
    (id, data) => {
      // prepare new wellboats object with changes made to referenced
      const newWellboats = wellboats.map((wellboat) =>
        wellboat.id === id ? { ...wellboat, ...data } : wellboat,
      );
      // update all wellboats and current one to trigger a re-render
      setWellboats(newWellboats);
      // replace current wellboat with the updated one
      setCurrentWellboat(
        newWellboats.find((wellboat) => wellboat.id === currentWellboat.id),
      );
    },
    [wellboats, currentWellboat],
  );

  const updateWellboat = React.useCallback(
    (id, data, endpoint = null) => {
      if (endpoint) {
        // actions must be performed on the server first to ensure data integrity
        // then if the request is successful, update the local state and re-renders
        axios
          .put(endpoint, data)
          .then((response) => {
            rebuildWellboats(id, response.data);
          })
          .catch((error) => {
            console.log(error);
            alert(
              "Ocurrió un error en el sistema, por favor reintente mas tarde",
            );
          });
      } else {
        // if no endpoint is provided, update the local state and re-render
        rebuildWellboats(id, data);
      }
    },
    [rebuildWellboats],
  );

  return (
    <WellboatsContext.Provider
      value={{
        wellboats,
        updateWellboat,
        currentWellboat,
        setCurrentWellboat,
        fetchWellboats,
        loadingWellboats,
        setLoadingCharts,
        error,
      }}
    >
      {children}
    </WellboatsContext.Provider>
  );
}

const useWellboats = () => React.useContext(WellboatsContext);

export { WellboatsProvider, useWellboats };
