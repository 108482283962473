import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import useChartsWidthAndHeight from "../../hooks/charts/useChartsWidthAndHeight";
import { ChartsReferenceLine } from "@mui/x-charts";

const epochToHour = (epoch) => {
  let timeFromEpoch = dayjs.unix(epoch);
  return timeFromEpoch.format(SHORT_TIME_FORMAT);
};

export default function LineChartComponent({ chart }) {
  const { width, height } = useChartsWidthAndHeight();

  const yAxisFormatter = React.useCallback((value, yAxisOptions) => {
    let valueToFormat = value;
    if (yAxisOptions.valueEnum) {
      valueToFormat = yAxisOptions.valueEnum[value];
    }
    return valueToFormat || "";
  }, []);

  const seriesFormatter = React.useCallback((value, serie) => {
    let valueToFormat = value;
    if (serie.valueEnum) {
      valueToFormat = serie.valueEnum[value];
    }
    return `${valueToFormat != null ? valueToFormat : "-"} ${serie.unit || ""}`;
  }, []);

  return (
    <LineChart
      skipAnimation
      dataset={chart.dataset}
      xAxis={[
        {
          ...chart.xAxis,
          domainLimit: "strict",
          valueFormatter: (value) => {
            return epochToHour(value);
          },
        },
      ]}
      yAxis={chart.yAxes.map((yAxis) => ({
        ...yAxis,
        domainLimit: "strict",
        valueFormatter: (value) => yAxisFormatter(value, yAxis),
      }))}
      series={chart.series.map((serie) => ({
        ...serie,
        showMark: false,
        valueFormatter: (value) => seriesFormatter(value, serie),
      }))}
      height={height}
      width={width}
      experimentalMarkRendering
    >
      {chart.referenceLines &&
        chart.referenceLines.map((refLine, index) => (
          <ChartsReferenceLine
            key={index}
            y={refLine.value}
            label={refLine.label}
            lineStyle={{
              stroke: "red",
              strokeWidth: 1,
              strokeDasharray: "4 4",
            }}
            labelAlign="end"
            labelStyle={{
              fontSize: 12,
            }}
          />
        ))}
    </LineChart>
  );
}
