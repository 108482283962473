import React from "react";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";

export default function SensorForm({
  sensor,
  setSensor,
  warehouses,
  variables,
  handleUpdateSensor,
}) {
  return (
    <Card sx={{ p: 2, width: "25rem" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {sensor.name}
      </Typography>
      <Stack spacing={2} mt={1} sx={{ width: "100%" }}>
        <FormControl fullWidth>
          <TextField
            fullWidth
            label="Nombre del sensor"
            value={sensor.name}
            onChange={(e) => {
              setSensor((prev) => ({ ...prev, name: e.target.value }));
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="warehouse">Bodega</InputLabel>
          <Select
            labelId="warehouse"
            label="Bodega"
            onChange={(e) =>
              setSensor((prev) => ({ ...prev, warehouseId: e.target.value }))
            }
            value={sensor.warehouseId || ""}
          >
            {warehouses.map((warehouse) => (
              <MenuItem key={warehouse.id} value={warehouse.id}>
                {warehouse.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="variable">Variable</InputLabel>
          <Select
            labelId="variable"
            label="Variable"
            onChange={(e) =>
              setSensor((prev) => ({ ...prev, variableId: e.target.value }))
            }
            value={sensor.variableId || ""}
          >
            {variables.map((variable) => (
              <MenuItem key={variable.id} value={variable.id}>
                {variable.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) =>
                  setSensor((prev) => ({ ...prev, enabled: e.target.checked }))
                }
                checked={sensor.enabled}
              />
            }
            label="Habilitado"
          />
        </FormControl>
        <Button
          component="label"
          color="primary"
          variant="contained"
          onClick={handleUpdateSensor}
        >
          Actualizar
        </Button>
      </Stack>
    </Card>
  );
}
