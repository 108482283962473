import React from "react";
import { Box, Divider } from "@mui/material";
import ChartBox from "./ChartBox";
import { Stack } from "@mui/system";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";

export default function GridCharts({ favoriteIcon, sortable, children }) {
  const { charts } = useWellboatCharts();

  const memoizedCharts = React.useMemo(() => {
    // charts are received in pairs sorted by warehouse
    // each pair is a row [chart1, chart2, special_chart3] in the array of rows
    // empty charts on a side are rendered too as empty boxes to preserve the grid layout
    return (
      charts?.rows?.map((row, index) => (
        <React.Fragment key={index}>
          <Stack spacing={3} direction="row" justifyContent="center">
            {/* left and right charts */}
            {!row[2] && (
              <>
                <ChartBox
                  chartRow={index}
                  chartPosition={0}
                  favoriteIcon={favoriteIcon}
                  sortable={sortable}
                  position={"left"}
                />
                <ChartBox
                  chartRow={index}
                  chartPosition={1}
                  favoriteIcon={favoriteIcon}
                  sortable={sortable}
                  position={"right"}
                />
              </>
            )}
            {/* center chart */}
            {row[2] && (
              <ChartBox
                chartRow={index}
                chartPosition={2}
                favoriteIcon={favoriteIcon}
                sortable={sortable}
                position={"center"}
              />
            )}
          </Stack>
          <Divider />
        </React.Fragment>
      )) || []
    );
  }, [charts, favoriteIcon]);

  return (
    <Box
      id="grid-charts"
      key="grid-charts"
      component="div"
      sx={{
        overflowY: "auto",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      {children}
      <Stack spacing={3}>{memoizedCharts}</Stack>
    </Box>
  );
}
