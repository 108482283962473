import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useAdmin } from "../../../../contexts/AdminContext";

export default function WarehousesRadioGroup() {
  const {
    selectedWellboat,
    warehouses,
    currentWarehouse,
    setCurrentWarehouse,
  } = useAdmin();

  const memoizingWarehouses = React.useMemo(() => {
    return warehouses
      .concat({
        id: -1,
        name: "Sin bodega",
      })
      .map((warehouse) => {
        return (
          <FormControlLabel
            key={warehouse.id}
            value={warehouse}
            control={<Radio />}
            label={warehouse.name}
            onChange={(e) => {
              setCurrentWarehouse(warehouse);
            }}
          />
        );
      });
  }, [selectedWellboat, warehouses]);

  return (
    <Box>
      <FormControl>
        <>
          <FormLabel>Bodegas</FormLabel>
          {warehouses?.length > 0 && (
            <RadioGroup value={currentWarehouse} row>
              {memoizingWarehouses}
            </RadioGroup>
          )}
        </>
      </FormControl>
    </Box>
  );
}
