import React from "react";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import SwitchSetting from "./SwitchSetting";
import { Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useWellboats } from "../../../contexts/WellboatsContext";

export default function AlarmSetting() {
  const { currentWellboat, updateWellboat } = useWellboats();

  const endpoint = React.useMemo(
    () => `/wellboats/${currentWellboat.id}/alarm_status`,
    [currentWellboat],
  );

  const changeAutomaticStatus = (alarmsAutomated) => {
    updateWellboat(currentWellboat.id, { alarmsAutomated }, endpoint);
  };

  const changeAlarmSetting = () => {
    updateWellboat(
      currentWellboat.id,
      {
        alarmsActive: !currentWellboat.alarmsActive,
      },
      endpoint,
    );
  };

  return (
    <Box sx={{ alignItems: "right", ml: "auto" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: !currentWellboat.alarmsAutomated ? "inline-block" : "none",
            mr: 3,
          }}
        >
          <Button
            component="label"
            color="primary"
            sx={{
              display: currentWellboat.alarmsActive ? "inline-block" : "none",
            }}
            variant="outlined"
            onClick={changeAlarmSetting}
            tabIndex={-1}
            size="small"
          >
            DESACTIVAR ALARMAS
          </Button>
          <Button
            component="label"
            color="primary"
            sx={{
              display: !currentWellboat.alarmsActive ? "flex" : "none",
              alignItems: "center",
            }}
            variant="contained"
            tabIndex={-1}
            onClick={changeAlarmSetting}
            startIcon={<NotificationsIcon />}
            size="small"
          >
            ACTIVAR ALARMAS
          </Button>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SwitchSetting
            status={currentWellboat.alarmsAutomated}
            changeStatus={changeAutomaticStatus}
          />
          <Typography sx={{ display: "inline-block" }}>Auto</Typography>
        </Box>
      </Box>
    </Box>
  );
}
