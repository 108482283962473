import React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SensorsIcon from "@mui/icons-material/Sensors";
import Checkbox from "@mui/material/Checkbox";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";

export default function ChartToolbox({ chart }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { fetchCharts, currentWellboat } = useWellboatCharts();

  const handleSensorsMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSensorUpdate = (sensor, value) => {
    axios
      .put(`/charts/${chart.id}/sensors/${sensor.id}`, {
        ...sensor,
        ...value,
      })
      .then(() => {
        fetchCharts({ newCurrentWellboat: currentWellboat });
      });
  };

  return (
    <>
      <IconButton onClick={handleSensorsMenuOpen}>
        <SensorsIcon />
      </IconButton>
      {open && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuList disablePadding>
            {chart.sensors.map((sensor) => (
              <MenuItem
                key={sensor.id}
                onClick={() =>
                  handleSensorUpdate(sensor, {
                    enabled: !sensor.enabled,
                  })
                }
                disabled={sensor.warehouse?.enabled === false}
              >
                <ListItemIcon>
                  <Checkbox checked={sensor.enabled} />
                </ListItemIcon>
                <ListItemText>{sensor.name}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      )}
    </>
  );
}
