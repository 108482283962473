import React from "react";
import Drawer from "@mui/material/Drawer";
import { Grid, Button, Typography, Box } from "@mui/material";
import FieldFormComponent from "../../../../common/FieldFormComponent";
import { useWellboats } from "../../../../../contexts/WellboatsContext";

export default function FormDrawerComponent({
  openForm,
  setOpenForm,
  setListCharts,
}) {
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
  });
  const [messageError, setMessageError] = React.useState("");
  const { currentWellboat } = useWellboats();

  React.useEffect(() => {
    setFormData({
      name: "",
    });
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!open) setMessageError("");
    setOpenForm(open);
  };

  const handleSaveForm = () => {
    axios
      .post(`/wellboats/${currentWellboat.id}/charts`, { ...formData })
      .then((response) => {
        setListCharts(response.data);
        setOpenForm(false);
      })
      .catch((response) => {
        setMessageError(response.response.data.message);
      });
  };

  const handleUpdateAttribute = (attribute, value) => {
    setFormData({ ...formData, [attribute]: value });
  };

  return (
    <Drawer anchor="right" open={openForm} onClose={toggleDrawer(false)}>
      <Grid container sx={{ p: 2 }}>
        <Grid item>
          <Typography variant="h6">Nuevo gráfico</Typography>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Typography>{messageError}</Typography>
        </Box>
        <FieldFormComponent
          fieldName={"name"}
          placeholder={"Nombre (requerido)"}
          value={formData.name}
          helperText={"El nombre es un campo requerido"}
          onChangeCallback={handleUpdateAttribute}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
          px: 2,
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={toggleDrawer(false)}
        >
          Cancelar
        </Button>
        <Button color="primary" variant="contained" onClick={handleSaveForm}>
          Guardar
        </Button>
      </Box>
    </Drawer>
  );
}
