import React from "react";
import Box from "@mui/material/Box";
import DelayedProgress from "./DelayedProgress";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";
import { useWellboats } from "../../contexts/WellboatsContext";

const DELAY = 400;

export default function LoaderProgress() {
  const { loadingWellboats } = useWellboats();
  const { loadingCharts } = useWellboatCharts();

  return (
    <>
      {loadingWellboats && (
        <Box sx={{ width: "100%" }}>
          <DelayedProgress key="delayed-progress" delay={DELAY} />
        </Box>
      )}
      {!loadingWellboats && loadingCharts && (
        <Box sx={{ width: "100%" }}>
          <DelayedProgress key="delayed-progress" delay={DELAY} />
        </Box>
      )}
    </>
  );
}
