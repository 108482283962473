import React from "react";
import { IconButton, Badge, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useCableContext } from "../../contexts/CableContext";
import Menu from "@mui/material/Menu";
import ListItemButton from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNotificationsContext } from "../../contexts/NotificationsContext";
import alarmSoundFile from "../../../assets/sounds/alarm.mp3";

const getNotificationTitle = (notification) => {
  return (
    <Typography variant="subtitle1" fontWeight={600} paddingX={2}>
      {notification.data.wellboat.name +
        " - " +
        notification.data.warehouse.name +
        " - " +
        notification.data.variable.name}
    </Typography>
  );
};

const getNotificationDescription = (notification) => {
  return (
    <Typography variant="body2" paddingX={2}>
      {"Sensor " +
        notification.data.sensor.name +
        " " +
        notification.data.sensor.value +
        " " +
        (notification.data.variable?.unit || "")}
    </Typography>
  );
};

export default function AlertIconNotification() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [iconColor, setIconColor] = React.useState(null);
  const { notificationsList, setNotificationsList } = useNotificationsContext();
  const [openNotificationsList, setOpenNotificationsList] =
    React.useState(false);
  const [notificationsCount, setNotificationsCount] = React.useState(
    notificationsList.length,
  );
  const {
    cableConnected,
    messageIsReceived,
    messageReceived,
    setMessageIsReceived,
  } = useCableContext();
  const loadStyleNotificationsIcon = (notificationsCount) => {
    if (notificationsCount && notificationsCount > 0) {
      return { color: "red" };
    }

    return { color: iconColor || "" };
  };
  const alarmSound = new Audio(alarmSoundFile);
  const currentPlayingSong = React.useRef(null);

  React.useEffect(() => {
    if (cableConnected && messageIsReceived) {
      setMessageIsReceived(false);
      appendNewNotification(messageReceived);
      setIconColor("red");
    }
  }, [cableConnected, messageIsReceived]);

  const appendNewNotification = (newNotification) => {
    setNotificationsList((prevNotifications) => {
      return [newNotification, ...prevNotifications];
    });
    setTimeout(() => {
      if (currentPlayingSong.current) {
        currentPlayingSong.current.pause();
        currentPlayingSong.current.currentTime = 0;
      }
      currentPlayingSong.current = alarmSound;
      alarmSound.play().catch((error) => {
        if (error.name != "NotAllowedError") {
          console.error(error);
        }
      });
    }, 0);
  };

  const handleClickAlarms = (e) => {
    setAnchorEl(e.currentTarget);
    const newOpenStatus = !openNotificationsList;
    setOpenNotificationsList(newOpenStatus);

    if (newOpenStatus) {
      setIconColor("");
      setOpenNotificationsList(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenNotificationsList(false);
  };

  const handleMarkAsReaded = (notification) => {
    axios
      .put(`/users/notifications/${notification.id}/read`)
      .then((response) => {
        const newNotificationsList = notificationsList.map((item) => {
          return item.id === notification.id
            ? { ...item, viewedAt: response.data.viewedAt }
            : item;
        });
        setNotificationsList(newNotificationsList);

        if (response.data.viewedAt) {
          setNotificationsCount(notificationsCount - 1);
        }
      });
  };

  React.useEffect(() => {
    setNotificationsCount(
      notificationsList.filter((item) => !item.viewedAt).length,
    );
  }, [notificationsList]);

  const alarmsNotificationsElements = React.useMemo(() => {
    if (!notificationsList || notificationsList.length === 0) {
      return (
        <ListItemButton>
          <ListItemText primary="No hay alarmas emitidas recientemente." />
        </ListItemButton>
      );
    }
    return notificationsList.map((notification) => {
      return (
        <ListItemButton
          divider
          onClick={(e) => {
            e.stopPropagation();
            handleMarkAsReaded(notification);
          }}
          key={notification.id}
          selected={!notification.viewedAt}
          secondaryAction={
            <IconButton
              value={notification.id}
              color={notification.viewedAt ? "" : "primary"}
            >
              <CheckCircleOutlineIcon />
            </IconButton>
          }
        >
          <ListItemText
            disableTypography
            primary={getNotificationTitle(notification)}
            secondary={getNotificationDescription(notification)}
          />
        </ListItemButton>
      );
    });
  }, [notificationsList]);

  return (
    <>
      <IconButton size="large" color="inherit" onClick={handleClickAlarms}>
        <Badge badgeContent={notificationsCount} color="error">
          <NotificationsIcon
            sx={loadStyleNotificationsIcon(notificationsCount)}
          />
        </Badge>
        <Menu
          id="notifications-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openNotificationsList}
          onClose={handleClose}
          sx={{ "& .MuiMenu-list": { padding: 0 } }}
        >
          {alarmsNotificationsElements}
        </Menu>
      </IconButton>
    </>
  );
}
