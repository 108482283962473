import React from "react";
import CatalogHeader from "./CatalogHeader";
import CatalogBody from "./CatalogBody";
import { Box } from "@mui/material";
import { NAVBAR_ID } from "../navbar/Navbar";
import useElementTotalHeight from "../../hooks/useElementTotalHeight";

export default function CatalogView() {
  const navBarTotalHeight = useElementTotalHeight(NAVBAR_ID);

  return (
    <Box
      key="catalog-view"
      component="div"
      sx={{
        height: `calc(100vh - ${navBarTotalHeight}px)`,
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CatalogHeader />
      <CatalogBody />
    </Box>
  );
}
