import * as React from "react";
import "../utils/axios";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { pink, deepOrange } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import useOnWindowResize from "../hooks/useOnWindowResize";
import AppRoutes from "../contexts/AppRoutes";
import Navbar from "../components/navbar/Navbar";
import LocalizationWrapper from "../utils/LocalizationWrapper";
import { WellboatsProvider } from "../contexts/WellboatsContext";
import { CatalogProvider } from "../contexts/CatalogContext";
import { CableProvider } from "../contexts/CableContext";
import { WellboatChartsProvider } from "../contexts/WellboatChartsContext";
import { NotificationsProvider } from "../contexts/NotificationsContext";

export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: deepOrange["A400"],
      dark: deepOrange[700],
      light: deepOrange[400],
      contrast: "#ffffff",
      hover: alpha(deepOrange[400], 0.15),
    },
    secondary: {
      main: pink[700],
      dark: pink[900],
      light: pink[600],
      contrast: "#ffffff",
    },
    grey: {
      main: "#9e9e9e",
      semiLight: "#8b8b8b",
      light: "#bdbdbd",
      lighter: "#eaeaea",
      dark: "#616161",
      contrastText: "#fafafa",
    },
    whiter: {
      main: "#ffffff",
    },
    background: {
      default: "white",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default function ApplicationLayout({ userName }) {
  const updateFontSize = () => {
    const viewportHeight = window.innerHeight;
    let baseFontSize;
    if (viewportHeight < 800) {
      // normal notebooks
      baseFontSize = "12px";
    } else if (viewportHeight < 1000) {
      // macbook pro
      baseFontSize = "15px";
    } else {
      baseFontSize = "16px"; // TV or big screens
    }
    document.documentElement.style.fontSize = baseFontSize;
  };

  useOnWindowResize(updateFontSize);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationWrapper>
        <BrowserRouter>
          <CssBaseline />
          <CableProvider>
            <NotificationsProvider>
              <WellboatsProvider>
                <CatalogProvider>
                  <WellboatChartsProvider>
                    <Navbar userName={userName} />
                    <AppRoutes />
                  </WellboatChartsProvider>
                </CatalogProvider>
              </WellboatsProvider>
            </NotificationsProvider>
          </CableProvider>
        </BrowserRouter>
      </LocalizationWrapper>
    </ThemeProvider>
  );
}
