import React from "react";
import { IconButton, List, ListItemText, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemButton from "@mui/material/ListItemButton";
import FormDrawerComponent from "./charts/FormDrawerComponent";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import useConfirm from "../../../../hooks/useConfirm";
import { Stack, Box } from "@mui/system";
import { useAdmin } from "../../../../contexts/AdminContext";

export default function ListChart() {
  const {
    selectedWellboat,
    listCharts,
    setListCharts,
    currentChart,
    setCurrentChart,
  } = useAdmin();
  const [chartDeleted, setChartDeleted] = React.useState();
  const [openForm, setOpenForm] = React.useState(false);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [getConfirmation, Confirmation] = useConfirm();

  React.useEffect(() => {
    if (selectedWellboat) {
      axios.get(`/wellboats/${selectedWellboat.id}/charts`).then((response) => {
        setListCharts(response.data);
        setCurrentChart(response.data[0]);
      });
    }
  }, [selectedWellboat]);

  const handleDeleteChart = (chart) => {
    getConfirmation({
      title: chart.name,
      content: "¿Estas seguro de eliminar el gráfico?",
    }).then((response) => {
      if (response) {
        axios
          .delete(`/wellboats/${selectedWellboat.id}/charts/${chart.id}`)
          .then(() => {
            const newCharts = listCharts.filter(
              (chartSearch) => chartSearch !== chart,
            );
            setChartDeleted(chart);
            setListCharts(newCharts);
            setOpenSnackBar(true);
          });
      }
    });
  };

  const memoizingListItemCharts = React.useMemo(() => {
    if (listCharts && listCharts.length > 0) {
      return listCharts.map((chart) => {
        return (
          <ListItemButton
            key={chart.id}
            selected={chart === currentChart}
            onClick={() => setCurrentChart(chart)}
            sx={{
              width: "23rem",
            }}
          >
            <ListItemText primary={chart.name}></ListItemText>
            {chart.chartType === "custom" && (
              <IconButton
                onClick={(e) => handleDeleteChart(chart)}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </ListItemButton>
        );
      });
    }
  }, [listCharts, currentChart]);

  return (
    <Stack spacing={1} direction="column" flexGrow={1} overflow="hidden">
      <Box component="div" display="flex" flexGrow={1} overflow="auto">
        <List>{memoizingListItemCharts}</List>
      </Box>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenForm(true)}
        sx={{
          alignSelf: "center",
        }}
      >
        Agregar gráfico
      </Button>
      <FormDrawerComponent
        openForm={openForm}
        setOpenForm={setOpenForm}
        currentChart={currentChart}
        setListCharts={setListCharts}
      />
      <Confirmation />
      {openSnackbar && chartDeleted && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackBar(false)}
        >
          <Alert
            onClose={() => setOpenSnackBar(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Se ha eliminado el gráfico {chartDeleted.name} con éxito
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
}
