import React from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import useConfirm from "../../../../hooks/useConfirm";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function SensorsList({
  warehouse,
  sensors,
  setSensors,
  index,
  sensorSelected,
  setSensorSelected,
}) {
  const [open, setOpen] = React.useState(false);
  const [getConfirmation, Confirmation] = useConfirm();
  const [openSnackbar, setOpenSnackBar] = React.useState(false);
  const [messageSnackbar, setMessageSnackbar] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("");

  const warehouseSensors = React.useMemo(() => {
    return sensors.filter((s) => s.warehouseId == warehouse.id);
  }, [sensors, warehouse]);

  React.useEffect(() => {
    const isWarehouseSensorSelected = warehouseSensors.some(
      (s) => s.id === sensorSelected?.id,
    );
    if (!open && isWarehouseSensorSelected) setOpen(true);
  }, [sensorSelected]);

  const handleDeleteSensor = (sensor) => {
    getConfirmation({
      title: sensor.name,
      content: `¿Estas seguro de eliminar el sensor ${sensor.name}?`,
    }).then((response) => {
      if (response) {
        axios
          .delete(`/sensors/${sensor.id}`)
          .then((_response) => {
            const newSensors = sensors.filter(
              (sensorSearch) => sensorSearch.id !== sensor.id,
            );
            setMessageSnackbar("Sensor eliminado con éxito");
            setSnackbarSeverity("success");
            setOpenSnackBar(true);
            setSensors(newSensors);
            setSensorSelected(null);
          })
          .catch((response) => {
            setMessageSnackbar(response.request.response);
            setSnackbarSeverity("error");
            setOpenSnackBar(true);
          });
      }
    });
  };

  return (
    <>
      {warehouseSensors.length > 0 && (
        <>
          <ListItemButton
            onClick={() => setOpen((prev) => !prev)}
            sx={{
              backgroundColor: grey[100],
            }}
          >
            <ListItemText primary={warehouse.name} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse key={index} in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {warehouseSensors.map((sensor) => (
                <ListItemButton
                  key={sensor.id}
                  onClick={() => {
                    setSensorSelected(sensor);
                  }}
                  selected={sensorSelected?.id === sensor.id}
                  sx={{ pl: 4 }}
                >
                  <ListItemText primary={sensor.name} />
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleDeleteSensor(sensor);
                    }}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <Confirmation />
          {openSnackbar && (
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnackbar}
              autoHideDuration={5000}
              onClose={() => setOpenSnackBar(false)}
            >
              <Alert
                onClose={() => setOpenSnackBar(false)}
                severity={snackbarSeverity}
                variant="filled"
                sx={{ width: "100%" }}
              >
                {messageSnackbar}
              </Alert>
            </Snackbar>
          )}
        </>
      )}
    </>
  );
}
