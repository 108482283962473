import React from "react";
import LineChartComponent from "./LineChartComponent";
import { Box, Stack, IconButton } from "@mui/material";
import useChartsWidthAndHeight from "../../hooks/charts/useChartsWidthAndHeight";
import SortingButtons from "./SortingButtons";
import FavoriteButton from "./FavoriteButton";
import ChartToolbox from "./ChartToolbox";
import SensorsIcon from "@mui/icons-material/Sensors";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";

export default function ChartBox({
  chartRow,
  chartPosition,
  favoriteIcon,
  sortable,
  position,
}) {
  const { width: chartsWidth } = useChartsWidthAndHeight();
  const [sortingControlsVisible, setSortingControlsVisible] =
    React.useState(false);
  const { charts } = useWellboatCharts();

  const chart = React.useMemo(
    () => charts?.rows?.[chartRow]?.[chartPosition],
    [charts, chartRow, chartPosition],
  );

  return (
    <Stack
      component="div"
      minWidth={`${chartsWidth + 50}px`}
      direction={position === "left" ? "row" : "row-reverse"}
      sx={{
        "& *[class$=MuiResponsiveChart-container]": {
          mx: "auto",
        },
      }}
      spacing={1}
      onMouseEnter={() => setSortingControlsVisible(true)}
      onMouseLeave={() => setSortingControlsVisible(false)}
    >
      {chart && (
        <>
          {favoriteIcon && <FavoriteButton chart={chart} />}
          <Stack
            direction="column"
            visibility={sortingControlsVisible ? "visible" : "hidden"}
            paddingY={3}
          >
            {chart.chartType === "custom" && <ChartToolbox chart={chart} />}
            {sortable && (
              <SortingButtons chart={chart} visible={sortingControlsVisible} />
            )}
            <IconButton sx={{ visibility: "hidden" }}>
              <SensorsIcon />
            </IconButton>
          </Stack>
          <Box>
            <LineChartComponent chart={chart} />
          </Box>
        </>
      )}
    </Stack>
  );
}
