import * as React from "react";
import { Link, Outlet, Navigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PeopleIcon from "@mui/icons-material/People";
import WarningIcon from "@mui/icons-material/Warning";
import MonitorIcon from "@mui/icons-material/Monitor";
import SensorsIcon from "@mui/icons-material/Sensors";
import { AdminProvider } from "../../contexts/AdminContext";

export default function AdminView() {
  const [tabSelected, setTabSelected] = React.useState(0);

  const handleChange = (_, newTab) => {
    setTabSelected(newTab);
  };

  return (
    <AdminProvider>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          minHeight: "600px",
        }}
      >
        <Tabs
          orientation="vertical"
          value={tabSelected}
          onChange={handleChange}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            icon={<PeopleIcon />}
            label="Usuarios"
            component={Link}
            to="usuarios"
          />
          <Tab
            icon={<WarningIcon />}
            label="Variables"
            component={Link}
            to="variables"
          />
          <Tab
            icon={<SensorsIcon />}
            label="Sensores"
            component={Link}
            to="sensores"
          />
          <Tab
            icon={<MonitorIcon />}
            label="Gráficos"
            component={Link}
            to="graficos"
          />
        </Tabs>

        <Box sx={{ p: 3, width: "auto" }}>
          <Outlet />
          {tabSelected == 0 && <Navigate to="usuarios" replace />}
        </Box>
      </Box>
    </AdminProvider>
  );
}
