import React from "react";
import ListChart from "./superadmin/ListCharts";
import SensorsContainer from "./superadmin/SensorsContainer";
import {
  Stack,
  Card,
  Typography,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useAdmin } from "../../../contexts/AdminContext";

export default function ChartsAdminBody() {
  const { wellboats, setSelectedWellboat, selectedWellboat } = useAdmin();

  return (
    <Stack direction="row" spacing={2} sx={{ height: "70vh" }}>
      <Card sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
          Wellboats
        </Typography>
        <List
          sx={{
            overflow: "auto",
            width: "12rem",
          }}
        >
          {wellboats.map((wellboat) => (
            <ListItemButton
              key={wellboat.id}
              onClick={() => setSelectedWellboat(wellboat)}
              selected={selectedWellboat == wellboat}
            >
              <ListItemText primary={wellboat.name} />
            </ListItemButton>
          ))}
        </List>
      </Card>
      {selectedWellboat && (
        <Card
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Typography variant="h6" sx={{ ml: 2, mb: 2 }}>
            Gráficos
          </Typography>
          <ListChart />
        </Card>
      )}
      <SensorsContainer />
    </Stack>
  );
}
