export default function setupAxiosInstance(
  axiosInstance,
  withGlobalInterceptors = true,
) {
  axiosInstance.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

  /**
   * Next we will register the CSRF Token as a common header with Axios so that
   * all outgoing HTTP requests automatically have it attached. This is just
   * a simple convenience so we don't have to attach every token manually.
   */

  let token = document.head.querySelector('meta[name="csrf-token"]');

  if (token) {
    axiosInstance.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
  } else {
    console.error("CSRF token not found on header");
  }

  if (withGlobalInterceptors) {
    // Add a response interceptor for unauthorized requests
    axiosInstance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response && error.response.status === 401) {
          // Handle 401 error globally
          window.location.href = "/401.html";
        }
        return Promise.reject(error);
      },
    );
  }
  return axiosInstance;
}

window.axios = setupAxiosInstance(require("axios"));
