import React, { createContext } from "react";
import { createConsumer } from "@rails/actioncable";

const CableContext = createContext();

const CableProvider = ({ children }) => {
  const cable = createConsumer();

  const [cableConnected, setCableConnected] = React.useState(false);
  const [messageIsReceived, setMessageIsReceived] = React.useState(false);
  const [messageReceived, setMessageReceived] = React.useState({});

  const connectToBackend = () => {
    cable.subscriptions.create("AlarmsChannel", {
      connected: () => {
        setCableConnected(true);
      },
      received: (data) => {
        setMessageIsReceived(true);
        setMessageReceived(data);
      },
      disconnected: () => {
        setCableConnected(false);
      },
    });
  };

  React.useEffect(() => {
    if (!cableConnected) {
      connectToBackend();
    }
  }, [cable, cableConnected]);

  return (
    <CableContext.Provider
      value={{
        cable,
        cableConnected,
        messageIsReceived,
        setMessageIsReceived,
        messageReceived,
      }}
    >
      {children}
    </CableContext.Provider>
  );
};

const useCableContext = () => React.useContext(CableContext);

export { CableProvider, useCableContext };
