import React from "react";

const CatalogContext = React.createContext();

function CatalogProvider({ children }) {
  const [liveStatus, setLiveStatus] = React.useState(true);
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [fetchLiveStatus, setFetchLiveStatus] = React.useState(true);

  React.useEffect(() => {
    if (fetchLiveStatus) {
      setStartTime(null);
      setEndTime(null);
    }
  }, [fetchLiveStatus]);

  return (
    <CatalogContext.Provider
      value={{
        liveStatus,
        setLiveStatus,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        fetchLiveStatus,
        setFetchLiveStatus,
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
}

export { CatalogContext, CatalogProvider };
