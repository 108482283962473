import React from "react";
import {
  FormControlLabel,
  Checkbox,
  RadioGroup,
  FormControl,
  Radio,
  FormLabel,
} from "@mui/material";

export default function RolesFieldFormComponent({
  roles,
  rolesSelected,
  setRolesSelected,
  onChangeCallback,
}) {
  const handleRoleChecked = (e, role) => {
    if (e.target.checked) {
      const newRoles = [...rolesSelected, role.id];
      setRolesSelected(newRoles);
      onChangeCallback("roles", newRoles);
    } else {
      const newRoles = rolesSelected.filter(
        (roleIteration) => roleIteration !== role.id,
      );
      setRolesSelected(newRoles);
      onChangeCallback("roles", newRoles);
    }
  };

  const [typeUser, setTypeUser] = React.useState("external");

  React.useEffect(() => {
    if (rolesSelected && rolesSelected.includes("external")) {
      setTypeUser("external");
    } else {
      setTypeUser("internal");
    }
  }, [rolesSelected]);

  const memoizingRoles = React.useMemo(() => {
    return roles.map((role) => {
      const isChecked = rolesSelected?.includes(role.id.toString())
        ? true
        : false;
      return (
        role.id != "external" && (
          <FormControlLabel
            key={role.id}
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  handleRoleChecked(e, role);
                }}
              />
            }
            label={role.label}
          />
        )
      );
    });
  }, [roles, rolesSelected]);

  const handleTypeUserChanged = (e) => {
    if (e.target.value == "external") {
      let newRoles = rolesSelected;
      if (!rolesSelected.includes("external")) {
        newRoles = [...rolesSelected, e.target.value];
      }
      setRolesSelected(newRoles);
      onChangeCallback("roles", newRoles);
    } else {
      const newRoles = rolesSelected.filter(
        (roleIteration) => roleIteration !== "external",
      );
      setRolesSelected(newRoles);
      onChangeCallback("roles", newRoles);
    }
    setTypeUser(e.target.value);
  };

  return (
    <>
      <FormControl>
        <FormLabel>Tipo de usuario</FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          onChange={handleTypeUserChanged}
          value={typeUser}
        >
          <FormControlLabel
            value="external"
            control={<Radio />}
            label="Usuario externo"
          />
          <FormControlLabel
            value="internal"
            control={<Radio />}
            label="Usuario interno"
          />
          {typeUser == "internal" && memoizingRoles}
        </RadioGroup>
      </FormControl>
    </>
  );
}
