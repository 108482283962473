import {
  Paper,
  Typography,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  TableContainer,
  TableBody,
  AppBar,
  Select,
  MenuItem,
  FormControl,
  Box,
  LinearProgress,
  Chip,
  Button,
  Alert,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StrippedTableRow from "../common/StrippedTableRow";
import { useWellboats } from "../../contexts/WellboatsContext";

const ALL_WELLBOATS = "Todos";
const EVENTS_TABLE_ID = "events-table";

const chipColorMapper = (eventType) => {
  switch (eventType) {
    case "alarm":
      return "error";
    case "alarms_activated":
    case "warehouse_enabled":
      return "success";
    case "alarms_deactivated":
    case "warehouse_disabled":
      return "warning";
    default:
      return "default";
  }
};

export default function ChangeLog({ visible, setVisible, height }) {
  const [events, setEvents] = React.useState([]);
  const [selectedWellboat, setSelectedWellboat] = React.useState(ALL_WELLBOATS);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const { wellboats } = useWellboats();
  const [hasMoreRecords, setHasMoreEvents] = React.useState(true);
  const [onlyAlarms, setOnlyAlarms] = React.useState(false);

  const getEvents = async ({ wellboatId, pageNumber }) => {
    const url = `/events?wellboat_id=${wellboatId || ""}&page=${pageNumber}&onlyAlarms=${onlyAlarms}`;
    setLoading(true);
    await axios
      .get(url)
      .then((response) => {
        if (response.data.wellboatId) {
          const wellboat = wellboats.find(
            (w) => w.id == response.data.wellboatId,
          );
          setSelectedWellboat(wellboat);
        } else {
          setSelectedWellboat(ALL_WELLBOATS);
        }
        setPage(response.data.page);
        setEvents(response.data.events);
        setError(false);
        setHasMoreEvents(response.data.hasMoreEvents);
      })
      .catch((error) => {
        console.error(error);
        setEvents([]);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (visible) {
      getEvents({ wellboatId: null, pageNumber: 1 });
    }
  }, [visible]);

  React.useEffect(() => {
    if (visible) {
      getEvents({ wellboatId: selectedWellboatId, pageNumber: 1 });
    }
  }, [onlyAlarms]);

  const selectedWellboatId = React.useMemo(() => {
    return selectedWellboat != ALL_WELLBOATS ? selectedWellboat?.id : null;
  }, [selectedWellboat]);

  React.useEffect(() => {
    let timer;
    if (visible) {
      timer = setInterval(
        () =>
          getEvents({
            wellboatId: selectedWellboatId,
            pageNumber: page,
          }),
        1 * 60 * 1000,
      );
    }

    return () => clearInterval(timer);
  }, [visible, selectedWellboatId, page, onlyAlarms]);

  React.useEffect(() => {
    const fn = async () => {
      await getEvents({ wellboatId: selectedWellboatId, pageNumber: 1 });
      const eventsTable = document.getElementById(EVENTS_TABLE_ID);
      if (eventsTable) {
        eventsTable.scrollTop = 0;
      }
    };
    fn();
  }, [selectedWellboatId]);

  const handleMoreEvents = React.useCallback(() => {
    getEvents({ wellboatId: selectedWellboatId, pageNumber: page + 1 });
  }, [selectedWellboatId, page, onlyAlarms]);

  const listEvents = React.useMemo(() => {
    return events.map((event, i) => {
      return (
        <StrippedTableRow
          index={i}
          key={i}
          sx={{ "& td": { border: "none", py: 0 } }}
        >
          <TableCell align="center" sx={{ width: "12.5rem" }}>
            <Chip
              label={event.label}
              color={chipColorMapper(event.eventType)}
              variant="outlined"
            />
          </TableCell>
          <TableCell align="center" sx={{ width: "12.5rem" }}>
            <Typography padding={0.5}>{event.wellboat.name}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography padding={0.5}>
              {dayjs(event.timestamp).format(
                SEMI_LONG_DATE_TIME_WITHOUT_YEAR_FORMAT,
              )}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography padding={0.5}>
              {event.data?.variable?.name || "-"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography padding={0.5}>
              {event.data?.sensor?.name || "-"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography padding={0.5}>
              {event.data?.sensor?.value || "-"}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography padding={0.5}>{event.author?.name}</Typography>
          </TableCell>
        </StrippedTableRow>
      );
    });
  }, [events]);

  return (
    <Paper
      elevation={5}
      sx={{
        overflowY: "hidden",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: "1px solid #e6e8eb",
        minHeight: "fit-content",
      }}
    >
      <AppBar
        position="sticky"
        color="inherit"
        onClick={() => setVisible((p) => !p)}
        sx={{ cursor: "pointer" }}
      >
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={0.5}>
          <AssignmentIcon fontSize="small" />
          <Typography variant="body1" padding={0.5} fontWeight={400}>
            Registro de cambios
          </Typography>
          <Box
            flexGrow={1}
            paddingLeft={3}
            sx={{
              visibility: visible ? "visible" : "hidden",
            }}
          >
            <Stack direction="row" spacing={3} alignItems="center">
              <FormControl
                variant="standard"
                size="small"
                sx={{ minWidth: 120 }}
              >
                <Select
                  value={selectedWellboat || ""}
                  onChange={(e) => setSelectedWellboat(e.target.value)}
                  label="Wellboat"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {[ALL_WELLBOATS, wellboats].flat().map((wellboat, index) => (
                    <MenuItem key={index} value={wellboat}>
                      {wellboat?.name || wellboat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                size="small"
                sx={{ minWidth: 120, marginLeft: 2 }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={onlyAlarms}
                      onChange={(e) => setOnlyAlarms(e.target.checked)}
                    />
                  }
                  label="Sólo alarmas"
                />
              </FormControl>
            </Stack>
          </Box>
          <IconButton>
            {visible ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ExpandLessIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      </AppBar>
      <Collapse in={visible} timeout="auto" unmountOnExit>
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <TableContainer
          id={EVENTS_TABLE_ID}
          sx={{ overflowY: "auto", height: height }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow sx={{ "& th": { p: 0.5 } }}>
                <TableCell align="center">Evento</TableCell>
                <TableCell align="center">Wellboat</TableCell>
                <TableCell align="center">Fecha</TableCell>
                <TableCell align="center">Variable</TableCell>
                <TableCell align="center">Sensor</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Usuario</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error && (
                <StrippedTableRow sx={{ "& td": { border: "none", py: 0 } }}>
                  <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                    <Alert severity="error">
                      Ocurrió un error al cargar los eventos
                    </Alert>
                  </TableCell>
                </StrippedTableRow>
              )}
              {!error && listEvents}
              {!error && (
                <StrippedTableRow
                  key={"load-more"}
                  sx={{ "& td": { border: "none", py: 0 } }}
                >
                  <TableCell colSpan={7} sx={{ textAlign: "center", py: 2 }}>
                    {hasMoreRecords && (
                      <Button
                        size="small"
                        onClick={handleMoreEvents}
                        variant="outlined"
                        color="primary"
                      >
                        Cargar más registros
                      </Button>
                    )}
                    {!hasMoreRecords && (
                      <Typography variant="body2">
                        No hay más registros
                      </Typography>
                    )}
                  </TableCell>
                </StrippedTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  );
}
