import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Statusitem from "./Statusitem";
import { useWellboats } from "../../contexts/WellboatsContext";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import Alert from "@mui/material/Alert";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";

const colorsServer = {
  true: "green",
  false: "red",
};

export default function WellboatsNavbar() {
  const {
    wellboats,
    currentWellboat,
    fetchWellboats,
    error: wellboatsError,
  } = useWellboats();
  const theme = useTheme();
  const { error: chartsError } = useWellboatCharts();

  const changeTab = React.useCallback(
    (event, wellboatId) => {
      fetchWellboats({ newCurrentWellboatId: wellboatId });
    },
    [wellboats],
  );

  return (
    <Stack component="div" spacing={1}>
      {wellboats.length > 0 && (
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={currentWellboat?.id}
          TabIndicatorProps={{
            sx: {
              backgroundColor: "transparent",
            },
          }}
          onChange={changeTab}
        >
          {wellboats.map((wellboat) => (
            <Tab
              key={wellboat.id}
              value={wellboat.id}
              sx={{
                py: 0,
                borderLeft: "1px solid #B8B8B84D",
                "&.MuiTab-root": {
                  borderTop: "5px solid black",
                  borderTopColor: colorsServer[wellboat.online],
                  color: "#B8B8B84D",
                },
                "&.Mui-selected": {
                  background: theme.palette.primary.hover,
                },
              }}
              label={<Statusitem wellboat={wellboat} />}
            />
          ))}
        </Tabs>
      )}
      {(wellboatsError || chartsError) && (
        <Alert severity="error">{wellboatsError || chartsError}</Alert>
      )}
    </Stack>
  );
}
