import React from "react";
import AlarmLiveSetting from "./settings/AlarmLiveSetting";
import GridCharts from "../charts/GridCharts";
import { useWellboats } from "../../contexts/WellboatsContext";
import { Box, Divider } from "@mui/material";
import ChangeLog from "./ChangeLog";
import WarehouseSideLabels from "../common/WarehouseSideLabels";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";
import LoaderProgress from "../common/LoaderProgress";

export default function DashboardBody({}) {
  const { currentWellboat } = useWellboats();
  const [visibleEventLog, setVisibleEventlog] = React.useState(false);
  const { charts, setSection } = useWellboatCharts();

  React.useEffect(() => {
    setSection("dashboard");
  }, []);

  return (
    <>
      {currentWellboat && (
        <>
          <Box
            id="dashboard-body"
            key="dashboard-body"
            sx={{
              px: 0,
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflowY: "hidden",
            }}
          >
            <AlarmLiveSetting />
            <Divider />
            <LoaderProgress />
            <WarehouseSideLabels warehouses={charts?.warehouses} />
            <GridCharts charts={charts} sortable />
          </Box>
          <ChangeLog
            visible={visibleEventLog}
            setVisible={setVisibleEventlog}
            height={"35vh"}
          />
        </>
      )}
    </>
  );
}
