import { Button, Box, Divider } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useWellboats } from "../../contexts/WellboatsContext";
import SensorsOffIcon from "@mui/icons-material/SensorsOff";
import SensorsIcon from "@mui/icons-material/Sensors";
import useConfirm from "../../hooks/useConfirm";

export default function WarehousesToolbar() {
  const { currentWellboat, fetchWellboats } = useWellboats();
  const [getConfirmation, Confirmation] = useConfirm();

  const handleDiableAllSensors = React.useCallback(
    (warehouse) => {
      getConfirmation({
        title: `Deshabilitar sensores de ${currentWellboat.name} en ${warehouse.name}`,
        content: `¿Estás seguro que deseas deshabilitar todos los sensores en ${warehouse.name}?`,
        severity: "warning",
      }).then((result) => {
        if (result) {
          axios
            .put(
              `/wellboats/${currentWellboat.id}/warehouses/${warehouse.id}`,
              {
                warehouse: {
                  enabled: false,
                },
              },
            )
            .then(() =>
              fetchWellboats({ newCurrentWellboatId: currentWellboat.id }),
            )
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
    [currentWellboat],
  );

  const handleEnabledAllSensors = React.useCallback(
    (warehouse) => {
      getConfirmation({
        title: `Habilitar sensores de ${currentWellboat.name} en ${warehouse.name}`,
        content: `¿Estás seguro que deseas habilitar todos los sensores en ${warehouse.name}?`,
        severity: "success",
      }).then((result) => {
        if (result) {
          axios

            .put(
              `/wellboats/${currentWellboat.id}/warehouses/${warehouse.id}`,
              {
                warehouse: {
                  enabled: true,
                },
              },
            )
            .then(() =>
              fetchWellboats({ newCurrentWellboatId: currentWellboat.id }),
            )
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
    [currentWellboat],
  );

  const warehouseButtons = React.useMemo(() => {
    return currentWellboat?.warehouses?.slice(0, 2)?.map((warehouse) => (
      <React.Fragment key={warehouse.id}>
        {warehouse.enabled && (
          <Button
            startIcon={<SensorsOffIcon />}
            size="small"
            variant="outlined"
            onClick={() => handleDiableAllSensors(warehouse)}
          >
            Deshabilitar {warehouse.name}
          </Button>
        )}
        {!warehouse.enabled && (
          <Button
            startIcon={<SensorsIcon />}
            size="small"
            variant="contained"
            onClick={() => handleEnabledAllSensors(warehouse)}
          >
            Habilitar {warehouse.name}
          </Button>
        )}
      </React.Fragment>
    ));
  }, [currentWellboat]);

  return (
    <Stack>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-around"
        paddingY={1}
      >
        {warehouseButtons}
      </Box>
      <Divider />
      <Confirmation />
    </Stack>
  );
}
