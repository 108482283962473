import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Checkbox, Box } from "@mui/material";
import { useAdmin } from "../../../../contexts/AdminContext";

export default function ListSensors({ sensorsSelected, setSensorsSelected }) {
  const { selectedWellboat, currentWarehouse, currentChart } = useAdmin();
  const [sensors, setSensors] = React.useState([]);

  React.useEffect(() => {
    if (currentWarehouse) {
      axios
        .get(
          `/wellboats/${selectedWellboat.id}/warehouses/${currentWarehouse.id}/sensors`,
        )
        .then((response) => {
          const sortedSensors = response.data.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setSensors(sortedSensors);
        });
    }
  }, [currentWarehouse]);

  React.useEffect(() => {
    if (currentChart) {
      setSensorsSelected(loadSensorsListFromCurrentChart());
    } else {
      setSensorsSelected({});
    }
  }, [currentChart]);

  const loadSensorsListFromCurrentChart = () => {
    if (!currentChart || (currentChart && sensorsSelected[currentChart.id])) {
      return sensorsSelected;
    }

    let sensors = sensorsSelected;

    sensors[currentChart.id] = currentChart.sensorsId;
    return sensors;
  };

  const handleSensorCheck = (e, sensor) => {
    if (e.target.checked) {
      if (!sensorsSelected[currentChart.id]) {
        sensorsSelected[currentChart.id] = {};
      }

      sensorsSelected[currentChart.id][sensor.id.toString()] = true;
    } else {
      delete sensorsSelected[currentChart.id][sensor.id.toString()];
    }

    setSensorsSelected({ ...sensorsSelected });
  };

  React.useEffect(() => {
    if (currentChart && !sensorsSelected[currentChart.id]) {
      setSensorsSelected(loadSensorsListFromCurrentChart());
    }
  }, [currentChart, sensorsSelected, loadSensorsListFromCurrentChart]);

  const memoizingListItemSensors = React.useMemo(() => {
    return sensors.map((sensor) => {
      if (currentChart) {
        return (
          <ListItem key={sensor.id}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={
                  sensorsSelected[currentChart.id]?.[sensor.id.toString()]
                    ? true
                    : false
                }
                tabIndex={-1}
                disableRipple
                onChange={(e) => {
                  handleSensorCheck(e, sensor);
                }}
              />
            </ListItemIcon>
            <ListItemText id={sensor.id} primary={sensor.name} />
          </ListItem>
        );
      }
    });
  }, [currentChart, sensors, sensorsSelected, handleSensorCheck]);

  return (
    <>
      {currentChart && (
        <Box
          component="div"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          overflow="auto"
        >
          <List>{memoizingListItemSensors}</List>
        </Box>
      )}
    </>
  );
}
