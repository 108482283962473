import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export default function VariablesBody() {
  const [variables, setVariables] = React.useState([]);
  const [openSnackbar, setOpenSnackBar] = React.useState(false);

  React.useEffect(() => {
    axios.get("/variables").then((response) => {
      setVariables(response.data);
    });
  }, []);

  const handleInputChange = (id, field, value) => {
    setVariables((prevVariables) =>
      prevVariables.map((variable) =>
        variable.id === id ? { ...variable, [field]: value } : variable,
      ),
    );
  };

  const handleSaveVariables = () => {
    axios.put("/variables/multiples", { variables: variables }).then(() => {
      setOpenSnackBar(true);
    });
  };

  const memoizingVariablesRows = React.useMemo(() => {
    return variables.map((variable) => {
      return (
        <TableRow key={variable.id}>
          <TableCell>{variable.name}</TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { width: "10ch" },
              }}
              noValidate
              autoComplete="off"
              padding={0}
            >
              <TextField
                value={variable.min?.toString() || ""}
                onChange={(e) =>
                  handleInputChange(variable.id, "min", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{
                  margin: 0,
                  padding: 0,
                }}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "10ch" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                value={variable.max?.toString() || ""}
                onChange={(e) =>
                  handleInputChange(variable.id, "max", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{
                  margin: 0,
                }}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "10ch" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                value={variable.unit?.toString() || ""}
                onChange={(e) =>
                  handleInputChange(variable.id, "unit", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{
                  margin: 0,
                }}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "10ch" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                value={variable.y_axis_min?.toString() || ""}
                onChange={(e) =>
                  handleInputChange(variable.id, "y_axis_min", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{
                  margin: 0,
                }}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "10ch" } }}
              noValidate
              autoComplete="off"
            >
              <TextField
                value={variable.y_axis_max?.toString() || ""}
                onChange={(e) =>
                  handleInputChange(variable.id, "y_axis_max", e.target.value)
                }
                variant="outlined"
                size="small"
                sx={{
                  margin: 0,
                }}
              />
            </Box>
          </TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "20ch" } }}
              noValidate
              autoComplete="off"
            >
              <Select
                size="small"
                value={variable.curve || ""}
                onChange={(e) =>
                  handleInputChange(variable.id, "curve", e.target.value)
                }
              >
                <MenuItem value="linear">Lineal</MenuItem>
                <MenuItem value="step_after">Pasos</MenuItem>
              </Select>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "30ch" } }}
              noValidate
              autoComplete="off"
            >
              <Select
                size="small"
                value={variable.kind || "others"}
                onChange={(e) =>
                  handleInputChange(variable.id, "kind", e.target.value)
                }
              >
                <MenuItem value="o2">Oxígeno</MenuItem>
                <MenuItem value="temperature">Temperatura</MenuItem>
                <MenuItem value="valve_closed_semiclosed_opened">
                  Válvula 3 Estados
                </MenuItem>
                <MenuItem value="valve_closed">Válvula Cerrada</MenuItem>
                <MenuItem value="valve_semiclosed">
                  Válvula Semicerrada
                </MenuItem>
                <MenuItem value="o2_saturation_calculated">
                  Saturación de O2 Calculada
                </MenuItem>
                <MenuItem value="others">Otros</MenuItem>
              </Select>
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  }, [variables]);

  return (
    <>
      <Card>
        <CardHeader title={"Configuración de Variables"} />
        <CardContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Variable</TableCell>
                <TableCell>Mínimo</TableCell>
                <TableCell>Máximo</TableCell>
                <TableCell>Unidad</TableCell>
                <TableCell>Eje Y mín</TableCell>
                <TableCell>Eje Y max</TableCell>
                <TableCell>Curva</TableCell>
                <TableCell>Tipo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{memoizingVariablesRows}</TableBody>
          </Table>
        </CardContent>
        <CardActions>
          <Button
            component="label"
            color="primary"
            variant="contained"
            onClick={handleSaveVariables}
            tabIndex={-1}
          >
            Guardar
          </Button>
        </CardActions>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackBar(false)}
      >
        <Alert
          onClose={() => setOpenSnackBar(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Las variables se han guardado con éxito
        </Alert>
      </Snackbar>
    </>
  );
}
