import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DashboardView from "../components/dashboard";
import CatalogView from "../components/catalog";
import AdminView from "../components/admin";
import VariablesBody from "../components/admin/sections/VariablesBody";
import SensorsBody from "../components/admin/sections/sensors/SensorsBody";
import UsersBody from "../components/admin/sections/UsersBody";
import ChartsAdminBody from "../components/admin/sections/ChartsAdminBody";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<DashboardView />} />
      <Route path="/catalog" element={<CatalogView />} />
      <Route path="/admin/*" element={<AdminView />}>
        <Route path="usuarios" index element={<UsersBody />} />
        <Route path="variables" element={<VariablesBody />} />
        <Route path="sensores" element={<SensorsBody />} />
        <Route path="graficos" element={<ChartsAdminBody />} />
      </Route>
    </Routes>
  );
}
