import React from "react";
import WarehousesRadioGroup from "./WarehousesRadioGroup";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Box,
  Input,
  Typography,
} from "@mui/material";
import ListSensors from "./ListSensors";
import { useAdmin } from "../../../../contexts/AdminContext";

export default function SensorsContainer() {
  const { currentChart, setCurrentChart, listCharts, setListCharts } =
    useAdmin();
  const [sensorsSelected, setSensorsSelected] = React.useState({});
  const [chartChanged, setChartChanged] = React.useState(false);

  const handleSaveSensors = () => {
    axios
      .post(`/charts/${currentChart.id}/sensors`, {
        chart_id: currentChart.id,
        sensorsId: Object.keys(sensorsSelected[currentChart.id]),
        chart: {
          name: currentChart.name,
        },
      })
      .then((e) => {
        if (chartChanged) {
          updateCurrentChartInList();
          setChartChanged(false);
        }
      });
  };

  const updateCurrentChartInList = () => {
    const newCharts = listCharts.map((chart) => {
      if (chart.id === currentChart.id) {
        return currentChart;
      }
      return chart;
    });
    setListCharts(newCharts);
  };

  return (
    <>
      {currentChart && (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "hidden",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">Nombre del gráfico</Typography>
              <Input
                value={currentChart?.name}
                onChange={(e) => {
                  setChartChanged(true);
                  setCurrentChart((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
                sx={{
                  my: 1,
                  width: "100%",
                }}
              />
            </Box>
            <WarehousesRadioGroup />
            <ListSensors
              sensorsSelected={sensorsSelected}
              setSensorsSelected={setSensorsSelected}
            />
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "center",
            }}
          >
            <Button
              component="label"
              color="primary"
              variant="contained"
              onClick={handleSaveSensors}
              tabIndex={-1}
              sx={{ mb: 1 }}
            >
              Guardar
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
}
