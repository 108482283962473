import React from "react";
import { CardHeader, Grid, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export default function SelectSensorsComponent({ warehouses, setWarehouses }) {
  const handleSensorChecked = (e, sensor) => {
    sensor.checked = e.target.checked;
    setWarehouses([...warehouses]);
  };

  const memoizingSensorsList = React.useMemo(() => {
    return warehouses.map((warehouse) => {
      return (
        <Grid item xs={6} key={warehouse.id}>
          <Card>
            <CardHeader
              title={<Typography variant="h6">{warehouse.name}</Typography>}
            />
            <CardContent sx={{ maxHeight: "12.5rem", overflowY: "auto" }}>
              {warehouse.sensors.map((sensor) => {
                return (
                  <FormGroup key={sensor.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            handleSensorChecked(e, sensor);
                          }}
                          checked={sensor.checked}
                          value={sensor.id}
                        />
                      }
                      label={sensor.name}
                    />
                  </FormGroup>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      );
    });
  }, [warehouses]);

  return (
    <Grid container spacing={3}>
      {memoizingSensorsList}
    </Grid>
  );
}
