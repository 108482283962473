import useMediaQuery from "@mui/material/useMediaQuery";

const CHARTS_HEIGHT = 300;

export default function useChartsWidthAndHeight() {
  const bigNotebook = useMediaQuery("(min-width:1600px)");

  return {
    width: bigNotebook ? 700 : 600,
    height: CHARTS_HEIGHT,
  };
}
