import React from "react";
import { Box } from "@mui/material";
import SwitchSetting from "./SwitchSetting";
import { Typography } from "@mui/material";
import { useWellboats } from "../../../contexts/WellboatsContext";
import { DateTimePicker } from "@mui/x-date-pickers";

export default function DisplayChartsSetting() {
  const { currentWellboat, updateWellboat } = useWellboats();

  const handleStarTimeChange = (startTime) => {
    updateWellboat(currentWellboat.id, { startTime });
  };

  const handleEndTimeChange = (endTime) => {
    updateWellboat(currentWellboat.id, { endTime });
  };

  const handleLiveChange = (live) => {
    let timesValues = {};
    if (live)
      timesValues = {
        startTime: null,
        endTime: null,
      };
    updateWellboat(currentWellboat.id, { live, ...timesValues });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
        <SwitchSetting
          status={currentWellboat?.live}
          changeStatus={handleLiveChange}
        />
        <Typography sx={{ display: "inline-block" }}>En vivo</Typography>
      </Box>
      <Box
        sx={{
          display: currentWellboat?.live ? "none" : "flex",
          alignItems: "center",
        }}
      >
        <Box>
          <DateTimePicker
            value={currentWellboat?.startTime || null}
            onChange={handleStarTimeChange}
            sx={{ display: "flex", width: "13rem" }}
            disableFuture={true}
            label="Desde"
            slotProps={{
              field: {
                size: "small",
              },
            }}
          />
        </Box>
        <Box sx={{ mx: 2, fontSize: "2.2rem", py: 0 }}>-</Box>
        <Box>
          <DateTimePicker
            value={currentWellboat?.endTime || null}
            onChange={handleEndTimeChange}
            sx={{ display: "flex", width: "13rem" }}
            disableFuture={true}
            label="Hasta"
            minDateTime={currentWellboat?.startTime}
            slotProps={{
              field: {
                size: "small",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
