import React from "react";
import useOnWindowResize from "./useOnWindowResize";

export default function useElementTotalHeight(elementId) {
  const [totalHeight, setTotalHeight] = React.useState(0);
  useOnWindowResize(() => setTopNavbarHeightAndBottomMargin());

  const setTopNavbarHeightAndBottomMargin = () => {
    const element = document.getElementById(elementId);
    const style = window.getComputedStyle(element);
    const marginTop = parseInt(style.marginTop);
    const marginBottom = parseInt(style.marginBottom);
    const elementTotalHeight = element.clientHeight + marginTop + marginBottom;
    setTotalHeight(elementTotalHeight);
  };

  return totalHeight;
}
