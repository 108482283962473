import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";

export default function WellboatAlarmStatus({ wellboat }) {
  const statusStyle = {
    true: { color: "#FF3D00" },
    false: { color: "#B8B8B84D" },
  };

  return <NotificationsIcon sx={statusStyle[wellboat.alarmsActive]} />;
}
