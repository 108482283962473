import React from "react";
import { CatalogContext } from "../../contexts/CatalogContext";
import GridCharts from "../charts/GridCharts";
import WarehouseSideLabels from "../common/WarehouseSideLabels";
import { Box } from "@mui/material";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";
import WarehousesToolbar from "./WarehousesToolbar";

export default function CatalogBody() {
  const { fetchLiveStaus, setStartTime, setEndTime } =
    React.useContext(CatalogContext);
  const { setSection } = useWellboatCharts();

  React.useEffect(() => {
    setSection("catalog");
  }, []);

  React.useEffect(() => {
    if (fetchLiveStaus) {
      setStartTime(null);
      setEndTime(null);
    }
  }, [fetchLiveStaus]);

  return (
    <>
      <Box
        id="catalog-body"
        sx={{
          px: 0,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflowY: "hidden",
        }}
      >
        <WarehouseSideLabels />
        <GridCharts favoriteIcon>
          <WarehousesToolbar />
        </GridCharts>
      </Box>
    </>
  );
}
