import React from "react";
import DisplayChartsSetting from "../dashboard/settings/DisplayChartsSetting";
import { Box } from "@mui/system";
import { Button, Divider } from "@mui/material";
import ModalExport from "./ModalExport";
import WellboatsNavbar from "../wellboats/WellboatsNavbar";
import LoaderProgress from "../common/LoaderProgress";
import { useWellboatCharts } from "../../contexts/WellboatChartsContext";

export default function CatalogHeader() {
  const [openExportModal, setOpenExportModal] = React.useState(false);

  const handleExportClick = () => {
    setOpenExportModal(true);
  };

  return (
    <>
      <WellboatsNavbar />
      <Box
        sx={{
          mx: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <DisplayChartsSetting />
        </Box>
        <Box sx={{ flexGrow: 1, textAlign: "right" }}>
          <Button
            component="label"
            onClick={handleExportClick}
            sx={{
              color: "#FF3D00",
              borderColor: "#FF3D00",
            }}
            variant="outlined"
            tabIndex={-1}
            size="small"
          >
            EXPORTAR
          </Button>
        </Box>
        {openExportModal && (
          <ModalExport
            openModal={openExportModal}
            setOpenModal={setOpenExportModal}
          />
        )}
      </Box>
      <Divider />
      <LoaderProgress />
    </>
  );
}
