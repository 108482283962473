import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState();
  const [iconContent, setIconContent] = useState();
  const [useTextField, setUseTextField] = useState(false);
  const [textFieldLabel, setTextFieldLabel] = useState("");
  const [mandatoryTextField, setMandatoryTextField] = useState(true);
  const [textFieldError, setTextFieldError] = useState(false);
  const [severity, setSeverity] = useState("error");
  const textInputRef = React.createRef();
  const [isAlert, setIsAlert] = useState(false);

  const getConfirmation = ({
    title,
    content = null,
    Icon = null,
    textField = false,
    textFieldLabel = "Comentarios",
    mandatoryTextField = false,
    severity = "error",
    isAlert = false,
  }) => {
    setTitle(title);
    setContent(content);
    setIconContent(
      Icon || (
        <HighlightOffIcon
          color={severity}
          sx={{ margin: "auto", fontSize: "5rem" }}
        />
      ),
    );
    setOpen(true);
    setUseTextField(textField);
    setTextFieldLabel(textFieldLabel);
    setMandatoryTextField(mandatoryTextField);
    setSeverity(severity);
    setIsAlert(isAlert);
    return new Promise((resolve) => setResolver(() => resolve));
  };

  const onClick = (status, text) => {
    if (status && mandatoryTextField && !text) {
      setTextFieldError(true);
      textInputRef.current.focus();
      return;
    }
    setOpen(false);
    resolver(text ? [status, text] : status);
  };

  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        textInputRef?.current?.focus();
      }, 100);
    }
  }, [open, textInputRef.current]);

  const Confirmation = () => (
    <>
      {open && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              px: { xs: 0, sm: 2 },
              py: 2,
              width: "100%",
            },
          }}
          open={open}
          disableEscapeKeyDown
        >
          {iconContent}
          <DialogTitle
            id="alert-dialog-title"
            component="div"
            textAlign="center"
          >
            {title}
          </DialogTitle>
          {content && (
            <DialogContent>
              <DialogContentText
                textAlign="center"
                id="alert-dialog-description"
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {content}
              </DialogContentText>
            </DialogContent>
          )}
          {useTextField && (
            <DialogContent>
              <FormControl fullWidth sx={{ py: 1 }}>
                <TextField
                  inputRef={textInputRef}
                  label={textFieldLabel}
                  multiline
                  rows={4}
                  defaultValue=""
                  autoFocus
                  required={mandatoryTextField}
                  error={textFieldError}
                />
              </FormControl>
            </DialogContent>
          )}
          <DialogActions sx={{ mt: "auto", mx: "auto" }}>
            <Button
              onClick={() => onClick(false)}
              variant="outlined"
              color={severity}
            >
              {isAlert ? "Ok" : "Cancelar"}
            </Button>
            {!isAlert && (
              <Button
                onClick={() =>
                  onClick(
                    true,
                    textInputRef.current?.value != ""
                      ? textInputRef.current?.value
                      : null,
                  )
                }
                variant="contained"
                color={severity}
                autoFocus
                type="submit"
              >
                Confirmar
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );

  return [getConfirmation, Confirmation];
};

export default useConfirm;
