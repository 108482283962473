import React from "react";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function AlarmSetting({ status, changeStatus, labelElement }) {
  const handleChange = (e) => {
    changeStatus(e.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        sx={{ marginRight: 0 }}
        control={
          <Switch
            size="small"
            checked={status || false}
            onChange={handleChange}
          />
        }
        label={labelElement}
      />
    </FormGroup>
  );
}
