import React from "react";
import { Stack, Typography } from "@mui/material";
import WellboatAlarmStatus from "./icons/WellboatAlarmStatus";
import WellboatAutomaticStatus from "./icons/WellboatAutomaticStatus";

export default function Statusitem({ wellboat }) {
  return (
    <Stack direction="row" spacing={1}>
      <WellboatAlarmStatus wellboat={wellboat} />
      <Typography sx={{ color: "black" }}>{wellboat.name}</Typography>
      <WellboatAutomaticStatus wellboat={wellboat} />
    </Stack>
  );
}
